/*
rendering viewport for the visualizer
*/

import * as THREE from "three";
import {STLLoader} from "three/examples/jsm/loaders/STLLoader";
import {TrackballControls} from 'three/examples/jsm/controls/TrackballControls.js';

export class c_visualizer
{
    /*
    constructor
    */
    constructor()
    {
        this.center = [];
    }

    /*
    initialize
    @param base_url - URL of the BCL Visualizer payload
    */
    async initialize(base_url, visualizer_info=null)
    {
        // filenames
        let fn_surface = ["breast_surface.stl", "tumor.stl", "tumor_plus_10mm.stl", "ports.stl", "needles.stl", "chest_wall.stl", "line_breast_to_tumor.stl", "line_tumor_to_chest_wall.stl"];
        let fn_metadata = "metadata.json";
        const number_surfaces = fn_surface.length;
        this.url_surface = new Array(number_surfaces).fill(null);
        this.url_metadata = "";
        this.url_clinical_notes = null;

        // surface properties
        this.color = [0xcccccc, 0x53c0f0, 0x53c0f0, 0xfcf294, 0x000000, 0x692e80, 0x005DFF, 0x00a500];
        this.opacity = [0.5, 1.0, 0.27, 1.0, 1.0, 1.0, 1.0, 1.0];

        if (base_url) 
        {
            this.url_metadata = base_url + "/" + fn_metadata;
            this.url_surface[0] = base_url + "/" + fn_surface[0];
            this.url_clinical_notes = base_url + "/clinical_notes.txt";
        }
        else if (visualizer_info) 
        {
            const url_metadata = visualizer_info["metadata.json"]; 
            this.url_metadata = url_metadata;

            const url_surface = visualizer_info["breast_surface.stl"];
            this.url_surface[0] = url_surface;

            const url_cnotes = visualizer_info["clinical_notes.txt"];
            if (url_cnotes != null)
                this.url_clinical_notes = url_cnotes; 
        }
        else 
        {
            const return_info = 
            {
                metadata_info: { case_id: "Can't initialize Visualizer." },
                success: false
            } 
    
            return return_info;
        }

        const url_metadata = this.url_metadata;

        // urls for optional files
        for (let j = 1; j < fn_surface.length; j++) {
            if (base_url) {
                this.url_surface[j] = base_url + '/' + fn_surface[j];
            }
            else if (visualizer_info) {
                const current_surface_name = fn_surface[j];
                const url_surface = visualizer_info[current_surface_name];
                this.url_surface[j] = url_surface; 
            }
        }
        
        // check if required files exist
        let required_file_urls = [this.url_surface[0], this.url_metadata];

        for (let i = 0; i < required_file_urls.length; i++)
        {
            let xhr = new XMLHttpRequest();
            xhr.open('HEAD', required_file_urls[i], false);
            xhr.send();
            /*
            if (xhr.status == "403")
            {
                const item = required_file_urls[i];
                console.log("cannot load", item);
            }
            */

            if (xhr.status == "404") 
            {
                const return_info = 
                {
                    metadata_info: { case_id: "INVALID CASE ID / URL" },
                    success: false
                } 
        
                return return_info;
            }
        }

        // load metadata
        const metadata_info = this.load_metadata();
        if (metadata_info == null)
        {
            const return_info = 
            {
                metadata_info: { case_id: "Invalid metadata." },
                success: false
            } 
    
            return return_info;
        }

        // initialize scene
        this.initialize_scene();

        // load surfaces
        this.load_surfaces();

        const return_info = 
        {
            metadata_info: metadata_info,
            success: true
        } 

        return return_info;
    }

    /*
    load metadata
    */
    load_metadata()
    {
        // load metadata file
        let xhttp;
        if (window.XMLHttpRequest)
            xhttp = new XMLHttpRequest();
        else
            xhttp = new ActiveXObject("Microsoft.XMLHTTP");
        //xhttp.overrideMimeType('text/xml');

        let mDoc = null;

        try 
        {
            xhttp.open("GET", this.url_metadata, false);
            xhttp.send(null);
            mDoc = xhttp.responseText;
        }
        catch (error) 
        {
            console.error("url metadata retrieval error", error);
            return null;
        }

        let jsdata = JSON.parse(mDoc);

        // metadata
        const visualizer_case_id = jsdata["case_id"];
        const visualizer_distance_tumor_to_chest_wall = jsdata["distance_tumor_to_chest_wall"].toFixed(1);
        const visualizer_distance_tumor_to_breast = jsdata["distance_tumor_to_breast"].toFixed(1);

        let n_depths = jsdata["needle_depth"];
        let n_length = jsdata["needle_length"];
        let t_ext = jsdata["tumor_extents_rotated"];
        let tm_ext = jsdata["tumor_margin_extents_rotated"];

        let visualizer_needle_length = "N/A";
        if (n_length != '0')
            visualizer_needle_length = (parseFloat(n_length) / 10.0).toFixed(1) + ' cm';

        let visualizer_tumor_extents0 = "";
        let visualizer_tumor_extents1 = "";
        let visualizer_tumor_extents2 = "";
        if (t_ext != null && t_ext.length > 2)
        {
            visualizer_tumor_extents0 = (t_ext[0] / 10.0).toFixed(1);
            visualizer_tumor_extents1 = (t_ext[2] / 10.0).toFixed(1);
            visualizer_tumor_extents2 = (t_ext[1] / 10.0).toFixed(1);
        }

        let visualizer_tumor_margin_extents0 = "";
        let visualizer_tumor_margin_extents1 = "";
        let visualizer_tumor_margin_extents2 = "";
        if (tm_ext != null && tm_ext.length > 2)
        {
            visualizer_tumor_margin_extents0 = (tm_ext[0] / 10.0).toFixed(1);
            visualizer_tumor_margin_extents1 = (tm_ext[2] / 10.0).toFixed(1);
            visualizer_tumor_margin_extents2 = (tm_ext[1] / 10.0).toFixed(1);
        }

        let exc_vol = jsdata["optimal_excision_volume"];
        let visualizer_optimal_excision_volume = "";
        if (exc_vol != null)
            visualizer_optimal_excision_volume = exc_vol.toFixed(1);
        
        this.cam_pos = jsdata["camera_position"];
        if (this.cam_pos == null)
            return null;
        this.cam_focal = jsdata["camera_focal_point"];
        this.tumor_position = jsdata["tumor_position"];
        if (this.tumor_position == null)
            return null;
        this.cam_dist = Math.sqrt(Math.pow(this.cam_pos[0]-this.cam_focal[0],2)
            + Math.pow(this.cam_pos[1]-this.cam_focal[1],2) + Math.pow(this.cam_pos[2]-this.cam_focal[2],2));
        this.tumor_stencil_position = jsdata["Tumor Stencil Cutout_position"];

        // clinical notes
        let clinical_notes = "";
        document.getElementById("clinical_notes").style.display = "block";

        if (this.url_clinical_notes != null)
        {
            fetch(this.url_clinical_notes)
            .then((res) => {
                if (res.status === 200)
                    return res.text();
                else
                {
                    console.log("couldn't find clinical notes file");
                    this.setState({ requestFailed: true });
                }
            })
            .then((text) => {
                clinical_notes = text;
                document.getElementById("clinical_notes").innerHTML = clinical_notes.replace(/(?:\r\n|\r|\n)/g, '<br>');
            })
            .catch((e) => document.getElementById("clinical_notes").innerHTML = "<b>Clinical notes:</b> None");
        }
        else
            document.getElementById("clinical_notes").innerHTML = "<b>Clinical notes:</b> None";

        // final metadata info
        const metadata_info = 
        {
            case_id: visualizer_case_id,
            distance_tumor_to_chest_wall: visualizer_distance_tumor_to_chest_wall,
            distance_tumor_to_breast: visualizer_distance_tumor_to_breast,
            tumor_extents0: visualizer_tumor_extents0,
            tumor_extents1: visualizer_tumor_extents1,
            tumor_extents2: visualizer_tumor_extents2,
            tumor_margin_extents0: visualizer_tumor_margin_extents0,
            tumor_margin_extents1: visualizer_tumor_margin_extents1,
            tumor_margin_extents2: visualizer_tumor_margin_extents2,
            needle_length: visualizer_needle_length,
            optimal_excision_volume: visualizer_optimal_excision_volume,
        };

        return metadata_info;
    }

    /*
    load surfaces
    */
    load_surfaces()
    {
        let loader = [];
        this.mesh = [];
        this.time_start_load_surfaces = Date.now();

        // loading manager
        let manager = new THREE.LoadingManager();

        // progress bar
        let progress_bar = document.getElementById('progress');
        let loading_overlay = document.getElementById('loading-overlay');

        // use closure to expose vp (this) and avoid global vp
        const vp = this

        manager.onProgress = function(item, loaded, total)
        {
            if (loaded / total * 100 > 99)
            {
                progress_bar.style.display = 'none';
                loading_overlay.style.display = 'none';
                vp.surfaces_loaded(vp);
            }
            else {
                progress_bar.style.width = (loaded / total * 100) + '%';
            }
        };

        // loop through surfaces
        for (let k = 0; k < this.url_surface.length; ++k)
        {
            // loader.push(new THREE.STLLoader(manager));
            loader.push(new STLLoader(manager));

            this.mesh.push(new THREE.Mesh());

            // load
            loader[k].load(vp.url_surface[k], function (buffer_geometry)
            {
                try
                {
                    let mat = new THREE.MeshLambertMaterial({ color: vp.color[k], side: THREE.DoubleSide });
                    mat.opacity = vp.opacity[k];
                    mat.transparent = (mat.opacity < 1.0);
                    if (mat.transparent)
                    {
                        mat.depthWrite = false;
                        mat.depthTest = true;
                    }
                    vp.mesh[k] = new THREE.Mesh(buffer_geometry, mat);
                    vp.mesh[k].doubleSided = true;
                }
                catch (ex)
                {
                    console.log(`exception in load, exception: ${ex}, this: ${vp}`, {ex, vp});
                }
            });
        }
    }

    /*
    initialize scene
    */
    initialize_scene()
    {
        // scene
        this.scene = new THREE.Scene();
        this.scene.background = new THREE.Color( 0xffffff );

        // camera
        this.width = window.innerWidth - 20;
        this.height = window.innerHeight - 30;
        this.camera = new THREE.PerspectiveCamera(24, this.width / this.height, 0.1, 2000);

        // renderer
        const canvas = document.querySelector('#three_canvas');
        this.camera = new THREE.PerspectiveCamera(24, this.width / this.height, 0.1, 2000);
        this.renderer = new THREE.WebGLRenderer({canvas, antialias: true});
        this.renderer.setSize(this.width, this.height, false);

        // controls
        // this.controls = new THREE.TrackballControls(this.camera, this.renderer.domElement);
        this.controls = new TrackballControls(this.camera, this.renderer.domElement);
        this.controls.rotateSpeed = 6.6;
        this.controls.zoomSpeed = 1.4;
        this.controls.panSpeed = 0.1;
        this.controls.minDistance = 100;
	    this.controls.maxDistance = 1600;
        this.controls.mouseButtons = {LEFT: THREE.MOUSE.RIGHT, MIDDLE: THREE.MOUSE.MIDDLE, 
            RIGHT: THREE.MOUSE.LEFT};

        // render
        this.renderer.render(this.scene, this.camera);

        // lighting
        let light = new THREE.AmbientLight(0xdddddd, 1.6);
        this.scene.add(light);

        light = new THREE.DirectionalLight(0xdddddd, 2.5);
        light.position.x = 300;
        light.position.y = 0;
        light.position.z = 0;
        light.position.normalize();
        this.scene.add(light);

        light = new THREE.DirectionalLight(0xdddddd, 2.6);
        light.position.x = -320;
        light.position.y = -80;
        light.position.z = -80;
        light.position.normalize();
        this.scene.add(light);

        // use closure to expose vp (this) and avoid global vp
        const vp = this;

        // window has been resized
        function window_resized()
        {
            vp.width = window.innerWidth - 20;
            vp.height = window.innerHeight - 30;
            vp.camera.aspect = vp.width / vp.height;
            vp.camera.updateProjectionMatrix();
            vp.renderer.setSize(vp.width, vp.height);
        }

        // resize callback
        window.addEventListener('resize', window_resized, false);

        // render/animate callback
        function animate()
        {
            requestAnimationFrame(animate);
            try
            {
                vp.controls.update();
                vp.renderer.render(vp.scene, vp.camera);
            }
            catch (e)
            {
                // console.log(`exception in animate, exception: ${e}, this: ${vp}`, {e, vp});
            }
        }

        animate();
    }

    /*
    surfaces have been loaded
    @param vp - viewport
    */
    surfaces_loaded(vp)
    {
        // get breast center
        let geometry = vp.mesh[0].geometry;
        geometry.computeBoundingBox();
        vp.center.x = (geometry.boundingBox.max.x + geometry.boundingBox.min.x) / 2;
        vp.center.y = (geometry.boundingBox.max.y + geometry.boundingBox.min.y) / 2;
        vp.center.z = (geometry.boundingBox.max.z + geometry.boundingBox.min.z) / 2;

        const vp_center = vp.center;
        // translations
        this.tumor_position[0] -= vp.center.x;
        this.tumor_position[1] -= vp.center.y;
        this.tumor_position[2] -= vp.center.z;
        for (let j = 0; j < this.tumor_stencil_position.length; j++)
        {
            this.tumor_stencil_position[j][0] -= vp.center.x;
            this.tumor_stencil_position[j][1] -= vp.center.y;
            this.tumor_stencil_position[j][2] -= vp.center.z;
        }

        let tra = new THREE.Matrix4().makeTranslation(-vp.center.x, -vp.center.y, -vp.center.z);
        for (let k = 0; k < vp.url_surface.length; ++k)
        {
            if (this.mesh[k] != null)
            {
                // translate mesh
                vp.mesh[k].applyMatrix4(tra);

                // add to scene
                vp.scene.add(vp.mesh[k]);
            }
        }

        // reset camera view
        vp.reset_view();
        vp.camera.up.set(0, 0, 1);

        // create extra surfaces
        vp.create_tumor_stencil_markers();

        // check loading time
        vp.time_end_load_surfaces = Date.now();
        let load_duration = (vp.time_end_load_surfaces - vp.time_start_load_surfaces) / 1000;
        console.log("load duration: ", load_duration);
        if (load_duration > 20.0)
            document.getElementById("connection_warning").style.visibility = "visible";
    }

    /*
    create tumor stencil markers
    */
    create_tumor_stencil_markers()
    {
        this.sprite_tumor_stencil = [];
        for (let i = 0; i < this.tumor_stencil_position.length; i++)
        {
            // create marker
            var map = new THREE.TextureLoader().load("media/marker_tumor_stencil.png");
            var spriteMat = new THREE.SpriteMaterial({
                map: map
            });

            this.sprite_tumor_stencil[i] = new THREE.Sprite(spriteMat);
            this.sprite_tumor_stencil[i].position.set(this.tumor_stencil_position[i][0],
                this.tumor_stencil_position[i][1], this.tumor_stencil_position[i][2]);
            this.sprite_tumor_stencil[i].scale.set(4, 4, 1);
            this.scene.add(this.sprite_tumor_stencil[i]);
        }
    }

    /*
    reset the camera
    */
    reset_view()
    {
        this.camera.position.x = this.cam_pos[0] - this.center.x;
        this.camera.position.y = this.cam_pos[1] - this.center.y;
        this.camera.position.z = this.cam_pos[2] - this.center.z;

        this.camera.lookAt(this.cam_focal[0] - this.center.x, this.cam_focal[1] - this.center.y, this.cam_focal[2] - this.center.z);
        this.controls.target.set(0, 0, 0);
        this.camera.up.set(0, 0, 1);
        this.controls.update();
    }

}

